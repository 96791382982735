var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ur-page-container',{staticClass:"msp",attrs:{"title":"VIP 선물 선택","show-title":true,"type":"popup"}},[_c('ur-box-container',{staticClass:"msp-cm-wrap",attrs:{"direction":"column","alignV":"start"}},[_c('ur-box-container',{staticClass:"ns-radio-list2 type-1",attrs:{"alignH":"start","componentid":"","direction":"row"}},[(_vm.lv_VipDcdGrdCd === '61')?_c('div',{staticClass:"pl24 pr24 pt30 pb12 fs16rem fwm"},[_vm._v("노블 (택1)")]):(_vm.lv_VipDcdGrdCd === '62')?_c('div',{staticClass:"pl24 pr24 pt30 pb12 fs16rem fwm"},[_vm._v("디어 (택1)")]):_vm._e(),_c('mo-list-item',{staticClass:"bd-T-Ty1"},[_c('msp-expand',{staticClass:"mo-list-expand w100",class:{'open' : _vm.lv_OpenTab === 'gift'},attrs:{"btn-area-first":"","title-first":"","expand-only-btn":"","btn-area-class":"fexTy3 "},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"list-item__contents"},[_c('div',{staticClass:"list-item__contents__title"},[_c('span',{staticClass:"name fwb"},[_vm._v("선물")])])])]},proxy:true},{key:"btn",fn:function(){return [_c('mo-button',{staticClass:"link-arrow down"})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"list-item__contents"},[_c('mo-list',{staticClass:"bd-T-Ty1",attrs:{"list-data":_vm.lv_VipGift_A},scopedSlots:_vm._u([{key:"list-item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('mo-list-item',{class:{'on' : item.cdVal === _vm.lv_RadioSelect}},[_c('div',{staticClass:"image-list-area mr20"},[_c('mo-radio',{staticClass:"bgwhite mr10",attrs:{"value":item.cdVal},model:{value:(_vm.lv_RadioSelect),callback:function ($$v) {_vm.lv_RadioSelect=$$v},expression:"lv_RadioSelect"}}),_c('img',{attrs:{"src":_vm.fn_getImgUrl(item.cdVal)}})],1),_c('div',{staticClass:"list-item__contents"},[_c('div',{staticClass:"list-item__contents__info mt0"},[_c('span',{staticClass:"fs14rem hauto"},[_vm._v(_vm._s('선물' + String(index + 1)))])]),_c('div',{staticClass:"list-item__contents__info mt0"},[_c('span',{staticClass:"hauto fwm crTy-bk1 fs16rem"},[_vm._v(_vm._s(item.cdValNm))])]),_c('div',{staticClass:"list-item__contents__info mt0"},[_c('span',{staticClass:"hauto fwm crTy-bk1 fs16rem"},[_c('mo-button',{staticClass:"pl0 crTy-blue4 min75",attrs:{"color":"normal","shape":"hyperlink","size":"large"},on:{"click":function($event){return _vm.fn_OpenMSPCM187P(item)}}},[_vm._v("상세보기")])],1)])])])]}}])})],1)]},proxy:true}])})],1),_c('mo-list-item',[_c('msp-expand',{staticClass:"mo-list-expand w100",class:{'open' : _vm.lv_OpenTab === 'subs'},attrs:{"btn-area-first":"","title-first":"","expand-only-btn":"","btn-area-class":"fexTy3 "},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"list-item__contents"},[_c('div',{staticClass:"list-item__contents__title"},[_c('span',{staticClass:"name fwb"},[_vm._v("기타서비스")])])])]},proxy:true},{key:"btn",fn:function(){return [_c('span',{staticClass:"vip fs14rem crTy-orange2 fwm dsInline"},[_vm._v("VIP외 양도불가 ")]),_c('mo-button',{staticClass:"link-arrow down"})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"list-item__contents"},[_c('mo-list',{staticClass:"bd-T-Ty1",attrs:{"list-data":_vm.lv_VipGift_B},scopedSlots:_vm._u([{key:"list-item",fn:function(ref){
var item = ref.item;
return [_c('mo-list-item',{class:{'on' : item.cdVal === _vm.lv_RadioSelect}},[_c('div',{staticClass:"image-list-area mr20"},[_c('mo-radio',{staticClass:"bgwhite mr10",attrs:{"value":item.cdVal},model:{value:(_vm.lv_RadioSelect),callback:function ($$v) {_vm.lv_RadioSelect=$$v},expression:"lv_RadioSelect"}}),_c('img',{attrs:{"src":_vm.fn_getImgUrl(item.cdVal)}})],1),_c('div',{staticClass:"list-item__contents"},[_c('div',{staticClass:"list-item__contents__info mt0"},[_c('span',{staticClass:"hauto fwm crTy-bk1 fs16rem"},[_vm._v(_vm._s(item.cdValNm))])]),_c('div',{staticClass:"list-item__contents__info mt0"},[_c('span',{staticClass:"hauto fwm crTy-bk1 fs16rem"},[_c('mo-button',{staticClass:"pl0 crTy-blue4 min75",attrs:{"color":"normal","shape":"hyperlink","size":"large"},on:{"click":function($event){return _vm.fn_OpenMSPCM187P(item)}}},[_vm._v("상세보기")])],1)])])])]}}])})],1)]},proxy:true}])})],1),_c('mo-list-item',[_c('msp-expand',{staticClass:"mo-list-expand w100",class:{'open' : _vm.lv_OpenTab === 'mdem'},attrs:{"btn-area-first":"","title-first":"","expand-only-btn":"","btn-area-class":"fexTy3 "},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"list-item__contents"},[_c('div',{staticClass:"list-item__contents__title"},[_c('span',{staticClass:"name fwb"},[_vm._v("건강검진")])])])]},proxy:true},{key:"btn",fn:function(){return [_c('span',{staticClass:"vip fs14rem crTy-orange2 fwm dsInline"},[_vm._v("VIP외 양도불가 ")]),_c('mo-button',{staticClass:"link-arrow down"})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"list-item__contents"},[_c('mo-list',{staticClass:"bd-T-Ty1",attrs:{"list-data":_vm.lv_VipGift},scopedSlots:_vm._u([{key:"list-item",fn:function(ref){
var item = ref.item;
return [_c('mo-list-item',{directives:[{name:"show",rawName:"v-show",value:(item.cdVal === '10'),expression:"item.cdVal === '10'"}],class:{'on' : item.cdVal === _vm.lv_RadioSelect}},[_c('mo-radio',{staticClass:"bgwhite mr10",attrs:{"value":item.cdVal},model:{value:(_vm.lv_RadioSelect),callback:function ($$v) {_vm.lv_RadioSelect=$$v},expression:"lv_RadioSelect"}}),_c('div',{staticClass:"list-item__contents"},[_c('div',{staticClass:"list-item__contents__info mt3"},[_c('span',{staticClass:"hauto fwm crTy-bk1 fs16rem"},[_vm._v("당사-병원 간 협약검진프로그램")])]),_c('div',{staticClass:"list-item__contents__info mt10"},[_c('div',{},[_c('span',{staticClass:"hauto fs14rem"},[_vm._v("VIP고객이 희망 병원/검진월을 담당 컨설턴트에게 신청 → 사랑On/유파트너 컨설턴트가 입력 → 컨설턴트 입력 다음날부터 "),_c('b',{staticClass:"fwn crTy-orange2"},[_vm._v("반드시 고객이 직접 병원에 전화")]),_vm._v("하여 검진일자/추가항목 결정 검진예약 취소 또는 변경은 콜센터 1544-7837 접수 (단, 취소변경 일정은 병원별 상이) ")])])])])],1)]}}])})],1)]},proxy:true}])})],1)],1),_c('ur-box-container',{staticClass:"ns-sch-area",attrs:{"alignV":"start","componentid":"","direction":"column"}},[_c('ur-box-container',{staticClass:"ns-btn-relative",attrs:{"alignV":"start","componentid":"","direction":"column"}},[_c('div',{staticClass:"relative-div"},[_c('mo-button',{staticClass:"ns-btn-round white",attrs:{"componentid":"","color":"primary","shape":"border","size":"medium"},on:{"click":_vm.fn_Cancel}},[_vm._v("취소")]),_c('mo-button',{staticClass:"ns-btn-round blue",attrs:{"componentid":"","color":"primary","shape":"border","size":"medium","disabled":_vm.lv_RadioSelect === ''},on:{"click":_vm.fn_GetCnsltInfo}},[_vm._v("다음")])],1)])],1)],1),_c('div',{staticClass:"ns-height70"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }