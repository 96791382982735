/*
 * 업무구분: 고객
 * 화 면 명: MSPCM187P
 * 화면설명: 선물 상세보기
 * 작 성 일: 2023.02.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container title="선물 상세보기" @on-header-left-click="fn_Cancel" :show-title="true" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="complete-img scroll-x pt30">
          <div class="data-img">
            <img v-bind:src='fn_getBigImgUrl(lv_CdVal)'>
          </div>
        </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
  * 공통 라이브러리 INCLUDE 영역	                                                   *
  ***********************************************************************************/

export default {
  /***********************************************************************************
    * Vue 파일 속성 정의 영역	                                                       *
    ***********************************************************************************/
  name: "MSPCM187P",
  screenId: "MSPCM187P",
  components: {
  },
  props: {
    pCdVal: String
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
    * Life Cycle 함수 정의 영역	                                                     *
    ***********************************************************************************/
  created() {
    // 이전 화면에서 넘어온 파라미터 세팅
    this.lv_CdVal = _.cloneDeep(this.$props.pCdVal)
  },

  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  /***********************************************************************************
    * 화면변수 선언 영역	                                                             *
    ***********************************************************************************/
  data() {
    return {
      lv_CdVal: ''
    };
  },

  /***********************************************************************************
    * 사용자 함수 정의 영역                                                           *
    ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_getBigImgUrl
     * 설명       : 화면에 매핑할 이미지 경로 세팅
     ******************************************************************************/
    fn_getBigImgUrl: function (cdVal) {
      if (cdVal === '10') return
      var imgString = 'gift_detail_' + cdVal
      return require('@/assets/img/customer/vip/' + imgString + '.jpg')
    },

    /******************************************************************************
     * Function명 : fn_Cancel
     * 설명       : 팝업 종료 
     ******************************************************************************/
    fn_Cancel () {
      this.$emit('onPopupClose')
    }
  }
};
</script>
<style scoped>
</style>