/*
 * 업무구분: 고객
 * 화 면 명: MSPCM185P
 * 화면설명: 검진처 안내
 * 작 성 일: 2023.02.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container title="건강검진 병원 현황" :show-title="true" type="popup" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="complete-img scroll-x pt30">
          <div class="data-img">
            <img src="@/assets/img/content/vip_mdemGuide.jpg" alt="" class="">
          </div>
        </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM185P",
    screenId: "MSPCM185P",
    components: {
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
    }
  };
</script>
<style scoped>
</style>