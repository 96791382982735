/*
 * 업무구분: 고객
 * 화 면 명: MSPCM181P
 * 화면설명: VIP서비스 신청 고객검색
 * 작 성 일: 
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container title="고객검색" :show-title="true" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">
        <!-- 조직관련 COMPONENT : 관리자 접근시 노출 -->
        <ur-box-container v-if="lv_IsMngr" direction="column" alignV="start" class="ns-sch-area">
          <SearchCondOrgCM ref="refSearchOrg" v-model="lv_OrgSearchData" :isFofType="1" :pOrgData="lv_OrgSearchData" @selectResult="fn_SlectCnsltResultPM"/>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">고객명</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <mo-text-field class="mb0" type="none" clearable underline placeholder="고객명을 입력해주세요." v-model="lv_SearchCustNm"/>  
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">신청현황</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_OdrStuCd" class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in lv_OdrStuCds" :value="item.value" :key="idx" v-show="idx < 3">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
            <mo-segment-wrapper solid primary v-model="lv_OdrStuCd" class="chip-type-wrap mt10">
              <mo-segment-button v-for="(item, idx) in lv_OdrStuCds" :value="item.value" :key="idx" v-show="idx > 2">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box pb20 mb80">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">VIP 등급</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_VipDcdGrdCd" class="chip-type-wrap chip-ty1">
              <mo-segment-button v-for="(item, idx) in lv_VipDcdGrdCds" :value="item.value" :key="idx" v-show="idx < 2">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
            <mo-segment-wrapper solid primary v-model="lv_VipDcdGrdCd" class="chip-type-wrap chip-ty1 mt10">
              <mo-segment-button v-for="(item, idx) in lv_VipDcdGrdCds" :value="item.value" :key="idx" v-show="idx > 1">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ResetBtnClick">초기화</mo-button>
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Search">조회</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
    <div class="ns-height40"></div>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import cmConstants from '@/config/constants/cmConstants.js'
import SearchCondOrgCM from '@/ui/cm/SearchCondOrgCM.vue'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCM181P",
  screenId: "MSPCM181P",
  components: {
    SearchCondOrgCM
  },
  props: {
    pIsMngr: Boolean, // 조직combox 권한자여부
    pOrgData: {
      type: Object,
      default: {}
    },
    pSearchCustNm: String, // 고객명
    pOdrStuCd: String,     // 신청현황
    pVipDcdGrdCd: String,  // VIP등급
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    const lv_Vm = this

    // post 호출 전 Progress 타입 설정
    window.vue.getStore('progress').dispatch('FULL')

    // 부모화면으로부터 전달받은 파라미터를 설정
    this.lv_IsMngr = this.$props.pIsMngr // 관리자여부
    this.lv_OrgSearchData = _.cloneDeep(this.$props.pOrgData) // 조직정보
    this.lv_SearchCustNm = _.cloneDeep(this.$props.pSearchCustNm) // 고객명
    this.lv_OdrStuCd = _.cloneDeep(this.$props.pOdrStuCd)
    this.lv_VipDcdGrdCd = _.cloneDeep(this.$props.pVipDcdGrdCd)

    this.userInfo = this.getStore('userInfo').getters.getUserInfo

    // 컴포넌트 동작을 위한 value값 임시 변경
    if (this.lv_OdrStuCd === '') this.lv_OdrStuCd = '999'
    if (this.lv_VipDcdGrdCd === '') this.lv_VipDcdGrdCd = '999'
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  watch: {
    lv_SearchCustNm () {
      let englishOrKorean = /[A-Za-z]/
      if (!this.$bizUtil.isEmpty(this.lv_SearchCustNm)) {
        if (englishOrKorean.test(this.lv_SearchCustNm)) {
          this.lv_SearchCustNm = this.lv_SearchCustNm.toUpperCase()
        }
      }
    }
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 조회조건 텍스트 배열

      userInfo: {},
      lv_SearchCustNm: '', // 고객명
      lv_OdrStuCd: '',
      lv_OdrStuCds: cmConstants.APPLY_STATUS_CD,  // 신청현황
      lv_VipDcdGrdCd: '',
      lv_VipDcdGrdCds: cmConstants.APPLY_RATING_CD,  // VIP등급

      // 조직컴포넌트
      isShowCnslt: false, // 담당컨설턴트
      lv_OrgSearchData: {}, // 조직정보
      lv_RtnOrgSearchData: {}, // 조직정보
      lv_IsMngr: false, // 관리자여부
    };
  },

  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SlectCnsltResultPM()
     * 설명       : 조직 변경시, 전역변수에 셋팅
     *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
     * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
     ******************************************************************************/
    fn_SlectCnsltResultPM (pData) {
      if ( this.isShowCnslt ) {
        // this.lv_OrgSearchData = pData
        this.lv_RtnOrgSearchData = pData
      }
      this.isShowCnslt = true
    },

    /******************************************************************************
     * Function명 : fn_ResetBtnClick
     * 설명       : 상세조회 조건 초기화
     *              (상세조회 내 초기화 버튼 클릭시 호출)
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_ResetBtnClick (e) {
      // 조직
      this.lv_OrgSearchData = {}
      this.lv_RtnOrgSearchData = {}
      this.lv_SearchCustNm = '' // 고객명
      this.lv_OdrStuCd = '999' // 신청현황
      this.lv_VipDcdGrdCd = '999'  // VIP등급
    },

    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 상세조회조건을 설정 후 부모화면에의 조회함수 호출
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_Search () {
      // 조직정보
      this.lv_ReturnVal.pIsMngr = this.lv_IsMngr // 관리자여부
      this.lv_ReturnVal.pOrgData = _.cloneDeep(this.lv_RtnOrgSearchData)

      this.lv_ReturnVal.lv_SearchCustNm = this.lv_SearchCustNm.trim() // 고객명
      this.lv_ReturnVal.lv_OdrStuCd = this.lv_OdrStuCd
      this.lv_ReturnVal.lv_VipDcdGrdCd = this.lv_VipDcdGrdCd

      // 초기화 여부
      let isInit = false
      if (this.$bizUtil.isEmpty(this.lv_ReturnVal.lv_SearchCustNm) && this.lv_ReturnVal.lv_OdrStuCd === '999' && this.lv_ReturnVal.lv_VipDcdGrdCd === '999') {
        if (this.lv_ReturnVal.pIsMngr) {
          if (this.userInfo.userId === this.lv_ReturnVal.pOrgData.cnslt.key) isInit = true
          else isInit = false
        } else isInit = true
      }

      // 조회조건 텍스트 배열
      this.lv_ReturnStr = [] // 초기화
      // 조회조건: 고객명, 신청현황, VIP등급
      this.lv_ReturnStr = [this.lv_ReturnVal.lv_SearchCustNm, this.lv_OdrStuCds.find(e => e.value === this.lv_OdrStuCd).text, this.lv_VipDcdGrdCds.find(e => e.value === this.lv_VipDcdGrdCd).text]

      this.$emit('onPopupSearch', {rtnData: this.lv_ReturnVal, srchOpt: this.lv_ReturnStr, isInit: isInit})
    },
  }
};
</script>
<style scoped>
</style>