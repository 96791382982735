/*
 * 업무구분: 고객
 * 화 면 명: MSPCM186P
 * 화면설명: 결과입력
 * 작 성 일: 2023.02.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container title="결과입력" @on-header-left-click="fn_Cancel" :show-title="true" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list pl24 pr24">
        <div class="list-item w100 pt30 pb10 pl0 pr0 bd-b-Ty1">
          <div class="list-item__contents">
            <div class="list-item__contents__title fexJsCtTy1 mb0"> 
              <span class="name txtSkip fexInt">{{lv_CustInfo.custNm}}</span>
              <mo-badge class="badge-sample-badge lightblue sm crTy-blue3 mt4" :text="lv_CustInfo.vipGrdNm" shape="status">{{lv_CustInfo.vipGrdNm}}</mo-badge>
              <!-- <mo-badge class="badge-sample-badge lightgreen sm crTy-blue3 mt4" text="드림앤드" shape="status">드림앤드</mo-badge> -->
              <!-- <mo-badge class="badge-sample-badge lightyellow sm crTy-blue3 mt4" text="노블앤드" shape="status">노블앤드</mo-badge>  -->
            </div>
          </div>
        </div> 
      </ur-box-container> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment con-area mt20 mb20">
        <mo-segment-wrapper solid primary v-model="lv_index">
          <mo-segment-button value="1" @click="fn_ChangeIdx('1')">방문</mo-segment-button>
          <mo-segment-button value="2" @click="fn_ChangeIdx('2')">미방문</mo-segment-button>
        </mo-segment-wrapper>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-radio-list2 mb30">
        <!-- START: Tab 1 -->
        <div ref="tab1" class="list-item-wrapper" v-if="lv_index =='1'" >
          <div class="list-item pt10 pb10 bdBN" :class="{'on' : radioSelected === 'a'}">
            <div class="list-item__main">
              <mo-radio v-model="radioSelected" value="a" checked></mo-radio>
              <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : radioSelected === 'a'}">기계약사항 확인</div>
            </div>
          </div>
          <div class="list-item pt10 pb10 bdBN" :class="{'on' : radioSelected === 'b'}">
            <div class="list-item__main">
              <mo-radio v-model="radioSelected" value="b"></mo-radio>
              <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : radioSelected === 'b'}">컨설팅(통합자산분석,보장분석)</div>
            </div>
          </div>
          <div class="list-item pt10 pb10 bdBN" :class="{'on' : radioSelected === 'c'}">
            <div class="list-item__main">
              <mo-radio v-model="radioSelected" value="c"></mo-radio>
              <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : radioSelected === 'c'}">보험금 청구</div>
            </div>
          </div>
          <div class="list-item pt10 pb10 bdBN" :class="{'on' : radioSelected === 'd'}">
            <div class="list-item__main">
              <mo-radio v-model="radioSelected" value="d"></mo-radio>
              <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : radioSelected === 'd'}">신상품/이벤트 소개</div>
            </div>
          </div>
          <div class="list-item pt10 pb10 bdBN" :class="{'on' : radioSelected === 'e'}">
            <div class="list-item__main">
              <mo-radio v-model="radioSelected" value="e"></mo-radio>
              <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : radioSelected === 'e'}">선물만 전달(직접방문 또는 택배)</div>
            </div>
          </div>
          <div class="list-item pt10 pb10 bdBN" :class="{'on' : radioSelected === 'f'}">
            <div class="list-item__main">
              <mo-radio v-model="radioSelected" value="f"></mo-radio>
              <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : radioSelected === 'f'}">건강검진/구독/유전체검사 신청 완료</div>
            </div>
          </div>
        </div>
        <!-- END: Tab 1 -->
        <!-- START: Tab 2 -->
        <div ref="tab2" class="list-item-wrapper" v-if="lv_index =='2'">
          <div class="list-item pt10 pb10 bdBN" :class="{'on' : radioSelected === '3'}">
            <div class="list-item__main">
              <mo-radio v-model="radioSelected" value="3" checked></mo-radio>
              <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : radioSelected === '3'}">고객의 방문 거부</div>
            </div>
          </div>
          <div class="list-item pt10 pb10 bdBN" :class="{'on' : radioSelected === '4'}">
            <div class="list-item__main">
              <mo-radio v-model="radioSelected" value="4"></mo-radio>
              <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : radioSelected === '4'}">FC 개인사정으로 미방문</div>
            </div>
          </div>
          <div class="list-item pt10 pb10 bdBN" :class="{'on' : radioSelected === '5'}">
            <div class="list-item__main">
              <mo-radio v-model="radioSelected" value="5"></mo-radio>
              <div class="list-item__contents fs17rem " :class="{'crTy-blue3' : radioSelected === '5'}">담당 FC 해촉</div>
            </div>
          </div>
        </div>
        <!-- END: Tab 2 -->
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="radioSelected === ''" @click="fn_udpVipSrvcRslt">확인</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCM186P",
  screenId: "MSPCM186P",
  components: {},
  props: {
    pCustInfo: Object,
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // post 호출 전 Progress 타입 설정
    window.vue.getStore('progress').dispatch('FULL')
    
    // 이전 화면에서 넘어온 파라미터 세팅
    this.lv_CustInfo = _.cloneDeep(this.$props.pCustInfo)

    // 초기 화면 세팅
    this.fn_Init()
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_CustInfo: {},
      lv_index: '1',    // 방문결과 탭 1:방문 2:미방문
      radioSelected: '',
    };
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_ChangeIdx
     * 설명       : 방문 / 미방문 탭 전환
     ******************************************************************************/
    fn_ChangeIdx (idx) {
      this.lv_index = idx;
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 초기 화면 세팅
     ******************************************************************************/
    fn_Init () {
      let lv_Vm = this
      let vipCustSrvcRsltCd = lv_Vm.lv_CustInfo.vipCustSrvcRsltCd

      if (lv_Vm.$bizUtil.isEmpty(vipCustSrvcRsltCd) || ['a', 'b', 'c', 'd', 'e', 'f'].indexOf(vipCustSrvcRsltCd) > -1) {
        lv_Vm.lv_index = '1'
      } else {
        lv_Vm.lv_index = '2'
      }

      lv_Vm.radioSelected = vipCustSrvcRsltCd
    },

    /******************************************************************************
     * Function명 : fn_udpVipSrvcRslt
     * 설명       : VIP 방문결과 저장
     ******************************************************************************/
    fn_udpVipSrvcRslt () {
      var lv_Vm = this

      var pParam = {
        custId: lv_Vm.lv_CustInfo.custId,
        cnsltNo: lv_Vm.lv_CustInfo.cnsltNo,
        vipId: lv_Vm.lv_CustInfo.vipId,
        VipCustSrvcRsltCd: lv_Vm.radioSelected
      }

      let trnstId = 'txTSSCM57U1'
      lv_Vm.post(lv_Vm, pParam, trnstId, 'U')
        .then(function (response) {
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECIU042') {
            lv_Vm.$emit('submit')
          } else {
            lv_Vm.getStore('toast').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_Cancel
     * 설명       : 팝업 종료 
     ******************************************************************************/
    fn_Cancel () {
      this.$emit('onPopupClose')
    }
  },
};
</script>
<style scoped>
</style>