/*
 * 업무구분: 고객
 * 화 면 명: MSPCM184P
 * 화면설명: 선택 서비스 확인
 * 작 성 일: 2023.02.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container title="선택 서비스 확인" :show-title="true" :type="(isCompleted && !isReselect)? 'subpage': 'popup'" class="msp">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container v-if="lv_CustInfo.odrStuCd === '2'" alignV="start" direction="row" class="pal1224"> 
        <mo-button color="normal" shape="border" size="large" class="blue flex-1 mr5" :class="{'btn_disabled--gray':lv_CustInfo.vipSrvcAplYmd !== today}" @click="fn_Confirm('reselect')">재선택</mo-button>
        <mo-button color="normal" shape="border" size="large" class="blue flex-1 ml5" :class="{'btn_disabled--gray':lv_CustInfo.vipSrvcAplYmd !== today}" @click="fn_OpenBottomSheet('msg1')">신청삭제</mo-button>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list pl24 pr24">
        <div class="list-item w100 pt10 pb10 pl0 pr0 bd-b-Ty1">
          <div class="list-item__contents">
            <div class="list-item__contents__title fexJsCtTy1 mb0"> 
              <span class="name txtSkip fexInt">{{lv_CustInfo.custNm}}</span>
              <mo-badge class="badge-sample-badge lightblue sm crTy-blue3 mt4" :text="lv_CustInfo.vipGrdNm" shape="status">{{lv_CustInfo.vipGrdNm}}</mo-badge>
              <!-- 배지 색 구분 필요할 경우: vipDcdGrdCd 61 노블, 62 디어-->
              <!-- <mo-badge class="badge-sample-badge lightgreen sm crTy-blue3 mt4" text="드림앤드" shape="status">드림앤드</mo-badge> -->
              <!-- <mo-badge class="badge-sample-badge lightyellow sm crTy-blue3 mt4" text="노블앤드" shape="status">노블앤드</mo-badge>  -->
            </div>
          </div>
        </div> 
      </ur-box-container>  
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">
        <!-- 선물 / 기타서비스 -->
        <template v-if="!isHospital">
          <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb0">
            <ur-box-container alignV="start" componentid="" direction="column" class="row-box bd-b-Ty bd-T-Ty1 bd-b-Ty2 pt16 pb11">
              <span class="w120px fs16rem fex-clumn fexJsCtTy4 h100"><span>SVIP No</span></span>
              <span class="flex-1 fs17rem fwb">{{lv_CustInfo.vipId}}</span>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="row-box bd-b-Ty2 pt16 pb11">
              <span class="w120px fs16rem fex-clumn fexJsCtTy4 h100"><span>품목명</span></span>
              <span class="flex-1 fs17rem fwb">{{lv_GiftInfo.cdValNm}}</span>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="row-box bd-b-Ty2 pt16 pb11">
              <span class="w120px fs16rem fex-clumn fexJsCtTy4 h100"><span>주문자</span></span>
              <span class="flex-1 fs17rem fwb">{{lv_CustInfo.cnsltNm}}</span>
            </ur-box-container>
            <ur-box-container v-show="isLifeCare !== '11'" alignV="start" componentid="" direction="column" class="row-box bd-b-Ty2 pt16 pb11">
              <span class="w120px fs16rem fex-clumn fexJsCtTy4 h100"><span>지점(대리점)</span></span>
              <span class="flex-1 fs17rem fwb">{{lv_CnsltInfo.hdofAddr}}</span>
            </ur-box-container>
          </ur-box-container>

          <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt10 pb20">
            <ur-box-container alignV="start" componentid="" direction="column" class="row-box pb80">
              <div class="bgcolor-1 pt16 pb16 pl20 pr20 bd-radius-10">
                <!-- 건강검진 -->
                <ul v-if="lv_GiftInfo.cdVal === '10' || Number.isNaN(Number(lv_GiftInfo.cdVal))" class="hauto fs14rem crTy-bk7 bullet-type-4">
                  <li>VIP서비스 이용 위해 삼성생명 및 업무 위탁사에서 필요로하는 <span class="crTy-blue2">FC성명, FC코드, 지점주소, 연락처 정보</span>를 제공.활용하는 것에 대하여 동의합니다.</li>
                  <li>계약자 본인만 이용 가능(양도 불가), 별도 검진권 제공되지 않습니다.</li>
                  <li>검진희망 일자 확정: 시스템 신청 익일 고객님이 해당병원에 전화하시어 검진 세부 일정 확정하셔야 예약이 완료됩니다. <span class="crTy-orange2">(병원에서 별도 안내 없음)</span></li>
                  <li>지원금액: 등급별 차등지원됩니다(노블: 65만원, 디어: 45만원)
                    <br>종합병원 검진 시 등급별 추가 비용 발생할 수 있으며, 제휴병원 기본 패키지 검진이용은 추가금액 없습니다. (삼성서울병원 기본 검진시에도 고객부담 발생)</li>
                  <li><span class="crTy-orange2">검진 항목은 각 검진처로 문의바라며, VIP 프로그램 이외 항목은 개인 비용부담이 발생합니다.</span></li>
                </ul>
                <!-- 선물 -->
                <ul v-if="isLifeCare !== '11'" class="hauto fs14rem crTy-bk7 bullet-type-4">
                  <li>VIP서비스 이용 위해 삼성생명 및 업무 위탁사에서 필요로하는 <span class="crTy-blue2">FC성명, FC코드, 지점주소, 연락처 정보</span>를 제공.활용하는 것에 대하여 동의합니다.</li>
                  <li>선물은 발주 및 포장작업으로 5영업일 이후 지점(대리점)으로 발송되며, 일정을 감안하여 선물신청 바랍니다.</li>
                  <li>상기 지점(대리점) 주소가 상이한 경우 [취소][신청정보 삭제하기]클릭 후 VIP전용 통합콜센터(1544-7837)로 연락하여 수정 바랍니다.</li>
                </ul>
                <!-- 기타서비스 -->
                <ul v-if="lv_GiftInfo.cdVal === '11'" class="hauto fs14rem crTy-bk7 bullet-type-4">
                  <li>VIP서비스 이용 위해 삼성생명 및 업무 위탁사에서 필요로하는 <span class="crTy-blue2">FC성명, FC코드, 지점주소, 연락처 정보</span>를 제공.활용하는 것에 대하여 동의합니다.</li>
                  <li>서비스 신청 후 FC님께 VIP고객이 기타서비스를 신청할 수 있는 URL 및 고객 서비스 코드를 전송해드립니다. 고객에게 전달 이후 고객이 직접 해당 URL에서 서비스 내용을 신청하셔야 최종 확정됩니다.(문의 : VIP통합콜센터, 1544-7837)</li>
                </ul>
                <!-- 유전체분석서비스 -->
                <ul v-if="lv_GiftInfo.cdVal === '12'" class="hauto fs14rem crTy-bk7 bullet-type-4">
                  <li>VIP서비스 이용 위해 삼성생명 및 업무 위탁사에서 필요로하는 <span class="crTy-blue2">FC성명, FC코드, 지점주소, 연락처 정보</span>를 제공.활용하는 것에 대하여 동의합니다.</li>
                  <li>서비스 신청 후 FC님께 VIP고객이 유전체분석서비스를 신청할 수 있는 URL 및 고객 서비스 코드를 전송해드립니다. 고객에게 전달 이후 고객이 직접 해당 URL에서 서비스 내용을 신청하셔야 최종 확정됩니다.(문의 : VIP통합콜센터, 1544-7837)</li>
                  <li>유전체분석서비스 시작 후 변경, 취소 불가하고, 등급별 제공 내역 이외 추가 진행은 개인 비용이 추가될 수 있습니다.</li>
                  <li>유전체분석서비스 세부 내역 및 주의사항은 사전 공지드립니다.</li>
                </ul>
              </div>
            </ur-box-container>
          </ur-box-container>
        </template>

        <!-- 건강검진 -->
        <template v-else>
          <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt10 pb0">
            <ur-box-container alignV="start" componentid="" direction="column" class="row-box bd-b-Ty2 pt16 pb11"> 
              <span class="w120px fs16rem fex-clumn fexJsCtTy4 h100"><span>SVIP No</span></span>
              <span class="flex-1 fs17rem fwb">{{lv_CustInfo.vipId}}</span>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="row-box bd-b-Ty2 pt16 pb11"> 
              <span class="w120px fs16rem fex-clumn fexJsCtTy4 h100"><span>품목명</span></span>
              <span class="flex-1 fs17rem fwb">{{lv_GiftInfo.cdValNm}}</span>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="row-box mt16"> 
              <span class="w120px fs16rem fex-clumn fexJsCtTy4 bd-b-Ty2 h100 pb12 min120"><span>지역선택</span></span>
              <span class="flex-1 bd-b-Ty2 pb12"><msp-bottom-select ref="bottomSelect" :items="lv_HospitalDist" v-model="lv_SelectedDstrt" @input="fn_SelObj('dist')" :disabled="isCompleted && !isReselect" underline class="ns-dropdown-sheet select-resposive" placeholder="선택" bottom-title="지역선택" closeBtn/></span>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="row-box mt16"> 
              <span class="w120px fs16rem fex-clumn fexJsCtTy4 bd-b-Ty2 h100 pb12 min120"><span>검진처</span></span>
              <span class="flex-1 bd-b-Ty2 pb12"><msp-bottom-select ref="bottomSelect" :items="lv_DstHospitalInfo" v-model="lv_SelectedHospital" @input="fn_SelObj('hspt')" :disabled="lv_DstHospitalInfo.length === 0 || ((isFirst || isCompleted) && !isReselect)" underline class="ns-dropdown-sheet select-resposive" placeholder="선택" bottom-title="검진처" closeBtn/></span>
            </ur-box-container>
            <ur-box-container alignH="start" direction="row" class="fexJsCtTy3">
              <mo-button color="normal" shape="hyperlink" size="large" class="pr0 pl0 crTy-blue4 min75" @click="fn_OpenMSPCM185P">검진처 안내</mo-button>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="row-box bd-b-Ty2 pt16 pb11"> 
              <span class="w120px fs16rem fex-clumn fexJsCtTy4 h100"><span>주문자</span></span>
              <span class="flex-1 fs17rem fwb">{{lv_CustInfo.cnsltNm}}</span>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="row-box mt16"> 
              <span class="w120px fs16rem fex-clumn fexJsCtTy4 bd-b-Ty2 h100 pb12"><span>검진 희망월</span></span>
              <span class="flex-1 bd-b-Ty2 pb12"><mo-month-picker v-model="lv_TargetMonth" :minDate="minMonth" :maxDate="maxMonth" :disabled="isCompleted && !isReselect" class="full wauto flex-1"/></span>
            </ur-box-container>
          </ur-box-container>
          
          <ur-box-container alignV="start" componentid="" direction="column" class="info-box mt10 pb20">
            <ur-box-container alignV="start" componentid="" direction="column" class="row-box pb80">
              <div class="bgcolor-1 pt16 pb16 pl20 pr20 bd-radius-10">
                <ul class="hauto fs14rem crTy-bk7 bullet-type-4">
                  <li>VIP서비스 이용 위해 삼성생명 및 업무 위탁사에서 필요로하는 <span class="crTy-blue2">FC성명, FC코드, 지점주소, 연락처 정보</span>를 제공.활용하는 것에 대하여 동의합니다.</li>
                  <li>계약자 본인만 이용 가능(양도 불가), 별도 검진권 제공되지 않습니다.</li>
                  <li>검진희망 일자 확정: 시스템 신청 익일 고객님이 해당병원에 전화하시어 검진 세부 일정 확정하셔야 예약이 완료됩니다. <span class="crTy-orange2">(병원에서 별도 안내 없음)</span></li>
                  <li>지원금액: 등급별 차등지원됩니다(노블: 65만원, 디어: 45만원)
                    <br>종합병원 검진 시 등급별 추가 비용 발생할 수 있으며, 제휴병원 기본 패키지 검진이용은 추가금액 없습니다. (삼성서울병원 기본 검진시에도 고객부담 발생)</li>
                  <li><span class="crTy-orange2">검진 항목은 각 검진처로 문의바라며, VIP 프로그램 이외 항목은 개인 비용부담이 발생합니다.</span></li>
                </ul>
              </div>
            </ur-box-container>
          </ur-box-container>
        </template>

      </ur-box-container>

      <!-- 확인 메시지(취소/확인) -->
      <mo-bottom-sheet ref="bottomSheetMsg1"  class="ns-bottom-sheet">
        <div class="customer-info-txt txt-center">
          <p class="ment">신청 건을 삭제하시겠습니까?</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_InitBottomSheet" name="취소">취소</mo-button>
              <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_DeleteApl" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <!-- 재선택/신청삭제(확인) -->
      <mo-bottom-sheet ref="bottomSheetMsg2" class="ns-bottom-sheet msp-bottomSt-alert">
        <div class="customer-info-txt txt-center">
          <p class="ment">신청일 이후 신청정보 삭제가 불가능합니다.<br>불가피한 변경이 있을 경우에는<br>VIP콜센터(1544-7837)로 연락 부탁드립니다.</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_InitBottomSheet" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Cancel">취소</mo-button>
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Confirm">확인</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import moment from 'moment'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPCM185P from '@/ui/cm/MSPCM185P' // 검진처 안내 팝업

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCM184P",
  screenId: "MSPCM184P",
  components: {
    MspBottomSelect,
    MSPCM185P,
  },

  props: {
    pHospitalInfo: Array,
    pCustInfo: Object,
    pGiftInfo: Object,
    pCnsltInfo: Object,
    pIsReselect: Boolean
  },

  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // post 호출 전 Progress 타입 설정
    window.vue.getStore('progress').dispatch('FULL')

    let lv_Vm = this

    // 상단탭 버튼 이벤트 
    this.actionSlot = {
      actionComponent: {
        template:'<div slot="action" class="icon-wrapper"><mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="deleteApl">신청삭제</mo-button></div>',
        methods: {
          deleteApl() {
            lv_Vm.fn_OpenBottomSheet('msg1')
          }
        }
      }
    }

    // 이전 화면에서 넘어온 파라미터 세팅
    this.lv_HospitalInfo = _.cloneDeep(this.$props.pHospitalInfo)
    this.lv_CustInfo = _.cloneDeep(this.$props.pCustInfo)
    this.lv_GiftInfo = _.cloneDeep(this.$props.pGiftInfo)
    this.lv_CnsltInfo = _.cloneDeep(this.$props.pCnsltInfo)
    this.isReselect = this.$props.pIsReselect
    // 신청완료 상태
    if (this.lv_CustInfo.odrStuCd === '2' || this.lv_CustInfo.odrStuCd === '4') this.isCompleted = true

    // 초기 화면 세팅
    this.fn_Init()
  },

  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  beforeDestroy () {
    this.fn_InitBottomSheet()
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 팝업 변수
      popup185: {},

      // 화면 변수
      lv_CnsltInfo: {},             // 사용자정보 obj
      lv_CustInfo: {},
      lv_GiftInfo: {},
      lv_SelectedDstrt: '선택',     // 선택된 지역(val)
      lv_SelectedDstrtObj: {},      // 선택된 지역(obj)
      lv_HospitalDist: [],          // 지역선택콤보 옵션 리스트
      lv_SelectedHospital: '선택',  // 선택된 검진처(val)
      lv_SelectedHospitalObj: {},   // 선택된 검진처(obj)
      lv_HospitalInfo: [],          // 검진처 리스트(코드)
      lv_DstHospitalInfo: [],       // 검진처 옵션 리스트
      lv_TargetMonth: '',           // 검진 희망월
      today: moment(new Date().toISOString()).format('YYYYMMDD'),
      minMonth: moment(new Date()).format('YYYY-MM-DD'), // 조회최소일자
      maxMonth: moment(new Date()).format('YYYY-12-31'),
      lv_inputParam: {      // VIP서비스 리스트 조회 서비스 호출 obj
        hofOrgNo: '',       // 사업부조직번호
        dofOrgNo: '',       // 지역단조직번호
        fofOrgNo: '',       // 지점조직번호
        cnsltNo: '',
        custNm: '',         // 고객명
        vipDcdGrdCd: '',    // VIP등급
        odrStuCd: '',       // 신청현황
        vipSrvcAplScCd: '', // VIP서비스신청구분코드
        hopDlvYmd: ''       // 희망배달일
      },
      pageRowCnt: '50',
      stndKeyListS1: [{stndKeyId: 'next_key', stndKeyVal: null}],
      stndKeyValS1: 'start',

      // 체크 변수
      isHospital: false,
      isLifeCare: '',
      isCompleted: false,
      isReselect: false,
      isFirst: true
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
  },

  watch: {
    lv_SelectedDstrtObj: function (val) {
      let lv_Vm = this
      let distCode = val.cdVal
      let ret = []

      let vipDcdGrdCd = lv_Vm.lv_CustInfo.vipDcdGrdCd
      // vipDcdGrdCd = '11'
      if (vipDcdGrdCd === '11') {
        for (let i in lv_Vm.lv_HospitalInfo) {
          lv_Vm.lv_HospitalInfo[i].value = lv_Vm.lv_HospitalInfo[i].key
          lv_Vm.lv_HospitalInfo[i].text = lv_Vm.lv_HospitalInfo[i].label
          if (lv_Vm.lv_HospitalInfo[i].cdVal.startsWith(distCode) && lv_Vm.lv_HospitalInfo[i].cdValNm.startsWith('[종합]')) {
            ret.push(lv_Vm.lv_HospitalInfo[i])
          }
        }
      } else {
        for (let i in lv_Vm.lv_HospitalInfo) {
          lv_Vm.lv_HospitalInfo[i].value = lv_Vm.lv_HospitalInfo[i].key
          lv_Vm.lv_HospitalInfo[i].text = lv_Vm.lv_HospitalInfo[i].label
          if (lv_Vm.lv_HospitalInfo[i].cdVal.startsWith(distCode)) {
            ret.push(lv_Vm.lv_HospitalInfo[i])
          }
        }
      }
      lv_Vm.lv_DstHospitalInfo = ret

      if (lv_Vm.lv_DstHospitalInfo.length > 0) {
        if (lv_Vm.isCompleted && !lv_Vm.isReselect) {
          // 신청완료 상태
          lv_Vm.isFirst = false
          lv_Vm.lv_SelectedHospital = lv_Vm.lv_CustInfo.vipSrvcAplScCd  // 병원코드
          lv_Vm.lv_SelectedHospitalObj = lv_Vm.lv_DstHospitalInfo.find(e => e.cdVal === lv_Vm.lv_SelectedHospital)
        } else {
          // 신청가능, 재선택 상태
          lv_Vm.isFirst = false
        }
      } else {
        // 처음 진입
        lv_Vm.isFirst = true // 검진처 콤보 비활성화
      }
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SelObj
     * 설명       : bottom select시 선택 obj 저장
     ******************************************************************************/
    fn_SelObj(str) {
      if (str === 'dist') {
        this.lv_SelectedDstrtObj = this.lv_HospitalDist.find(e => e.value === this.lv_SelectedDstrt)
      } else if (str === 'hspt') {
        this.lv_SelectedHospitalObj = this.lv_DstHospitalInfo.find(e => e.value === this.lv_SelectedHospital)
      }
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기 세팅
     ******************************************************************************/
    fn_Init() {
      let lv_Vm = this

      lv_Vm.isHospital = lv_Vm.lv_GiftInfo.cdVal === '10' || Number.isNaN(Number(lv_Vm.lv_GiftInfo.cdVal)) // 건강검진 신청/재신청 하는경우에는 10 혹은 a1 b1이런식으로 들어옴
      lv_Vm.isLifeCare = ''
      if (['11', '12'].indexOf(lv_Vm.lv_GiftInfo.cdVal) > -1) { // 신청완료 상태가 아니면서 라이프케어서비스를 신청/재신청 하는경우에는 11로 해야함
        lv_Vm.isLifeCare = '11'
      }
      // as-is처럼 기타서비스 신청완료 화면을 완전히 선물과 같이 할 경우 주석 해제
      // if ((lv_Vm.lv_CustInfo.odrStuCd === '2' || lv_Vm.lv_CustInfo.odrStuCd === '4') && !lv_Vm.isReselect) { // 신청완료 상태인 경우 선물신청화면과 동일하게 해야함
      //   lv_Vm.isLifeCare = '' 
      //   lv_Vm.isHospital = false
      // }

      let tmp_hospitalDist = {}
      lv_Vm.lv_HospitalDist = []
      if (lv_Vm.lv_CustInfo.vipDcdGrdCd !== '11') {
        tmp_hospitalDist = lv_Vm.getStore('code').getters.getState.get('VIP_MDEM_DSTRT_CD')
      } else {
        tmp_hospitalDist = lv_Vm.getStore('code').getters.getState.get('VIP_CLLG_MDEM_DSTRT_CD')
      }
      for (let i in tmp_hospitalDist) {
        // key, label => value, text
        tmp_hospitalDist[i].value = tmp_hospitalDist[i].key
        tmp_hospitalDist[i].text = tmp_hospitalDist[i].label
        lv_Vm.lv_HospitalDist.push(tmp_hospitalDist[i])
      }
      lv_Vm.lv_HospitalDist = [...lv_Vm.lv_HospitalDist]
      // 신청완료 상태가 아닐때
      if ((lv_Vm.lv_CustInfo.odrStuCd !== '2' && lv_Vm.lv_CustInfo.odrStuCd !== '4') || lv_Vm.isReselect) {
        // lv_Vm.vipMdemHopMntCd = lv_Vm.getStore('code').getters.getState.get('VIP_MDEM_HOP_MNT_CD')
        lv_Vm.lv_SelectedDstrt = ''
        lv_Vm.lv_TargetMonth = [String(moment(new Date()).format('YYYY')), String(moment(new Date()).format('MM'))]
      } else {
        // 신청완료 상태일 때
        // 지역, 검진처 세팅
        lv_Vm.lv_SelectedDstrt = lv_Vm.lv_CustInfo.vipSrvcAplScCd.substr(0,1)
        if (lv_Vm.isHospital) {
          lv_Vm.fn_SelObj('dist')
          lv_Vm.lv_GiftInfo.cdValNm = '건강검진'
        }
        if ( lv_Vm.lv_CustInfo.hopDlvYmd !== '' ) {
          lv_Vm.lv_TargetMonth = lv_Vm.lv_CustInfo.hopDlvYmd.split('-')
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckValidation
     * 설명       : 입력값 유효성 체크
     ******************************************************************************/
    fn_CheckValidation () {
      let lv_Vm = this
      let rslt = true

      if (lv_Vm.$bizUtil.isEmpty(lv_Vm.lv_SelectedDstrt) || lv_Vm.lv_SelectedDstrt === '선택') {
        lv_Vm.getStore('toast').dispatch('ADD', '지역을 선택하세요.')
        rslt = false
      } else if (lv_Vm.$bizUtil.isEmpty(lv_Vm.lv_SelectedHospital) || lv_Vm.lv_SelectedHospital === '선택') {
        lv_Vm.getStore('toast').dispatch('ADD', '검진처를 선택하세요.')
        rslt = false
      }

      return rslt
    },

    /******************************************************************************
     * Function명 : fn_DeleteApl
     * 설명       : 선택삭제 버튼 선택 이벤트
     ******************************************************************************/
    fn_DeleteApl () {
      let lv_Vm = this
      let pParam = {
        custId: lv_Vm.lv_CustInfo.custId,
        cnsltNo: lv_Vm.lv_CustInfo.cnsltNo,
        vipId: lv_Vm.lv_CustInfo.vipId
      }
      
      let trnstId = 'txTSSCM56U3'
      lv_Vm.post(lv_Vm, pParam, trnstId, 'U')
        .then(function (response) {
          if (lv_Vm.DEBUG) console.log(response)
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECIU042') {
            lv_Vm.fn_Confirm('del')
          } else {
            lv_Vm.getStore('toast').dispatch('ADD', '[서비스신청내역삭제] 오류가 발생했습니다. 다시 시도해주십시오')
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_Cancel
     * 설명       : 취소 버튼 선택 이벤트
     ******************************************************************************/
    fn_Cancel () {
      this.$emit('onPopupClose')
    },

    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 확인 버튼 선택 이벤트
     ******************************************************************************/
    fn_Confirm (str) {
      let rtnData = {}

      // 입력 데이터 유효성 체크
      if (this.isHospital && !this.fn_CheckValidation()) return

      // 신청삭제 완료되었을 때
      if (str === 'del') {
        let rtnData = {flag: 'success', msg: '삭제가 완료되었습니다'}
        this.$emit('afterUpdat', rtnData)
        return 
      }

      // 재선택 시
      if (str === 'reselect') {
        // 신청당일이 아니면 리턴
        if (this.lv_CustInfo.vipSrvcAplYmd !== this.today) {
          this.fn_OpenBottomSheet('msg2')
          return
        }
        rtnData = {pCustInfo: this.lv_CustInfo, pGiftInfo: this.lv_GiftInfo}
        this.$emit('reselect', rtnData)
        return
      }

      // 신청가능 상태일 때: 신청 서비스 호출
      if (!this.isCompleted || this.isReselect) {
        this.fn_GetServiceVali()
        // this.fn_GetService()
      } else {
        // 신청완료 상태일 때: 팝업 닫기
        this.$emit('onPopupClose')
      }
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : VIP서비스신청 서비스 호출
     ******************************************************************************/
    fn_GetService () {
      let lv_Vm = this

      let pParam = {
        custId: lv_Vm.lv_CustInfo.custId,
        cnsltNo: lv_Vm.lv_CustInfo.cnsltNo,
        vipId: lv_Vm.lv_CustInfo.vipId
      }
      if (lv_Vm.lv_GiftInfo.cdVal === '10') {
        // 건강검진
        if (lv_Vm.lv_TargetMonth[1].length === 1) lv_Vm.lv_TargetMonth[1] = '0' + lv_Vm.lv_TargetMonth[1]
        let month = lv_Vm.lv_TargetMonth[0] + lv_Vm.lv_TargetMonth[1]

        pParam.vipSrvcAplScCd = lv_Vm.lv_SelectedHospitalObj.cdVal
        pParam.hopDlvYmd = month
        pParam.prdtNm = lv_Vm.lv_SelectedHospitalObj.cdValNm
        pParam.frnoPstcd = ''
        pParam.bknoPstcd = ''
        pParam.etcAddrEncr = ''
      } else if (lv_Vm.lv_GiftInfo.cdVal === '11' || lv_Vm.lv_GiftInfo.cdVal === '12') {
        // 라이프케어 서비스
        pParam.prdtNm = lv_Vm.lv_GiftInfo.cdValNm
        pParam.hopDlvYmd = ''
        pParam.vipSrvcAplScCd = lv_Vm.lv_GiftInfo.cdVal
        pParam.etcAddrEncr = lv_Vm.lv_CnsltInfo.hdofAddr
        pParam.frnoPstcd = ''
        pParam.bknoPstcd = ''
      } else {
        pParam.vipSrvcAplScCd = lv_Vm.lv_GiftInfo.cdVal
        pParam.hopDlvYmd = ''
        pParam.prdtNm = lv_Vm.lv_GiftInfo.cdValNm
        pParam.frnoPstcd = lv_Vm.lv_CnsltInfo.frnoPstcd
        pParam.bknoPstcd = lv_Vm.lv_CnsltInfo.bknoPstcd
        pParam.etcAddrEncr = lv_Vm.lv_CnsltInfo.hdofAddr
      }

      let trnstId = 'txTSSCM56U1'
      lv_Vm.post(lv_Vm, pParam, trnstId, 'U')
        .then(function (response) {
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECIU042') {
            if (lv_Vm.isReselect) {
              // 180-184 emit(서비스 재선택 완료)
              let rtnData = {flag: 'success', msg: '신청이 완료되었습니다'}
              lv_Vm.$emit('afterUpdat', rtnData)
            } else {
              // 184-182-183 emit(서비스 신청 완료)
              lv_Vm.$emit('afterSave')
            }
          } else {
            lv_Vm.getStore('toast').dispatch('ADD', '[서비스신청] 오류가 발생했습니다. 다시 시도해주십시오')
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_GetServiceVali
     * 설명       : 서비스 호출(VIP서비스신청 목록 조회)
     ******************************************************************************/
    fn_GetServiceVali () {
      // const trnstId = 'txTSSCM56S1'// VIP서비스신청 리스트 조회
      const trnstId = 'txTSSCM48S8'// VIP서비스신청 리스트 조회
      const auth = 'S'
      const lv_Vm = this
      let pParams = {}
      let confirm = this.getStore('confirm')

      this.lv_inputParam.fofOrgNo = this.lv_CnsltInfo.orgNo
      this.lv_inputParam.cnsltNo = this.lv_CnsltInfo.cnsltNo
      this.lv_inputParam.custNm = this.lv_CustInfo.custNm
      pParams = this.lv_inputParam

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'ECIU116') {
            let responseList = response.body.iCVipCustSrvcAplInfoVO
            if (responseList !== null) {
              if (responseList[0].chnlCustId === lv_Vm.lv_CustInfo.chnlCustId) {
                if (responseList[0].odrStuCd === '9' || responseList[0].odrStuCd === '4') {
                  lv_Vm.getStore('toast').dispatch('ADD', '신청할 수 없는 상태의 고객입니다.')
                  return
                }
              }
              // 더보기 키값
              lv_Vm.stndKeyListS1 = response.trnstComm.stndKeyList
              if (lv_Vm.stndKeyListS1 === null || lv_Vm.stndKeyListS1[0] === null || lv_Vm.stndKeyListS1[0].stndKeyVal === null || lv_Vm.stndKeyListS1[0].stndKeyVal.trim() === '') {
                // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_Vm.stndKeyValS1 = 'end'
                lv_Vm.fn_GetService()
              } else {
                // 다음페이지 데이터 존재함
                lv_Vm.stndKeyValS1 = 'more'
                lv_Vm.fn_GetServiceVali()
              }
            } 
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_OpenBottomSheet
     * 설명       : 바텀시트 오픈
     ******************************************************************************/
    fn_OpenBottomSheet (str) {
      let lv_Vm = this

      if (str === 'msg1') {
        // 신청당일이 아니면 리턴
        if (lv_Vm.lv_CustInfo.vipSrvcAplYmd !== lv_Vm.today) {
          lv_Vm.fn_OpenBottomSheet('msg2')
          return
        }
        lv_Vm.$refs.bottomSheetMsg1.open()
      } else if (str === 'msg2') {
        lv_Vm.$refs.bottomSheetMsg2.open()
      }
    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet () {
      let lv_Vm = this

      // 바텀시트 초기화
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.bottomSheetMsg1 !== undefined) {
          lv_Vm.$refs.bottomSheetMsg1.close()
        }
        if (lv_Vm.$refs.bottomSheetMsg2 !== undefined) {
          lv_Vm.$refs.bottomSheetMsg2.close()
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPCM185P
     * 설명       : 검진처 안내 팝업 오픈
     ******************************************************************************/
    fn_OpenMSPCM185P () {
      this.popup185 = this.$bottomModal.open(MSPCM185P, {
        properties: {
        },
        listeners: {
          onPopupClose: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup185)
          }
        }
      })
    },
  }
};
</script>
<style scoped>
</style>